/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';
import { setCurrentCompanyId } from 'lib/auth';
import {
  fetchProductsByCompany,
  getProductTypes,
  updateCompaniesProducts,
} from 'api/products';

export const fetchCompaniesProductsThunk = createAsyncThunk(
  'products/fetchCompaniesProducts',
  async ({ companyId }) => {
    const response = await fetchProductsByCompany({ companyId });
    return response;
  },
);

export const updateCompaniesProductsThunk = createAsyncThunk(
  'products/updateCompaniesProducts',
  async ({ productsList }) => {
    const response = await updateCompaniesProducts({ productsList });
    return response;
  },
);

export const getProductTypesThunk = createAsyncThunk(
  'products/getProductTypes',
  async () => {
    const response = await getProductTypes();
    return response;
  },
);

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    companiesProducts: {
      value: [],
      resourceStatus: RESOURCE_STATUS.IDLE,
    },

    updateCompaniesProducts: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    productTypes: {
      value: [],
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
  },
  reducers: {
    updateCurrentCompany(state, action) {
      const { companyId } = action.payload;
      setCurrentCompanyId(companyId);
    },
  },
  extraReducers: {
    [fetchCompaniesProductsThunk.fulfilled]: (state, action) => {
      state.companiesProducts.value = action.payload.data;
      state.companiesProducts.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchCompaniesProductsThunk.pending]: state => {
      state.companiesProducts.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchCompaniesProductsThunk.rejected]: state => {
      state.companiesProducts.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [updateCompaniesProductsThunk.fulfilled]: state => {
      state.updateCompaniesProducts.error = null;
      state.updateCompaniesProducts.resourceStatus = RESOURCE_STATUS.READY;
    },
    [updateCompaniesProductsThunk.pending]: state => {
      state.updateCompaniesProducts.error = null;
      state.updateCompaniesProducts.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updateCompaniesProductsThunk.rejected]: (state, action) => {
      state.updateCompaniesProducts.error = action.error;
      state.updateCompaniesProductsresourceStatus = RESOURCE_STATUS.ERROR;
    },
    [getProductTypesThunk.fulfilled]: (state, action) => {
      state.productTypes.value = action.payload.data;
      state.productTypes.resourceStatus = RESOURCE_STATUS.READY;
    },
    [getProductTypesThunk.pending]: state => {
      state.productTypes.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [getProductTypesThunk.rejected]: state => {
      state.productTypes.resourceStatus = RESOURCE_STATUS.ERROR;
    },
  },
});

export const getCompaniesProducts = state => state.products.companiesProducts;
export const getProductTypesList = state => state.products.productTypes;

export default productsSlice.reducer;
