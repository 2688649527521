import axios from 'lib/axios';

export const fetchCompanies = search =>
  axios.get('/companies/all', {
    params: {
      name: search,
    },
  });

export const getCompany = id => axios.get(`/companies/${id}`);
