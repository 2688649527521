import axios from 'lib/axios';

export const signIn = ({ email, password }) =>
  axios.post('/auth/login', { email, password });

export const verifyToken = headers =>
  axios.get('/auth/validate_token', { params: headers });

export const signOut = () => axios.delete('/auth/logout');

export const resetPassword = ({ email, redirectUrl }) =>
  axios.post('/auth/password', { email, redirect_url: redirectUrl });

export const changePassword = ({
  password,
  passwordConfirmation,
  resetPasswordToken,
}) =>
  axios.put('/auth/password', {
    password,
    password_confirmation: passwordConfirmation,
    reset_password_token: resetPasswordToken,
  });

export const setupAccount = ({
  password,
  passwordConfirmation,
  invitationToken,
}) =>
  axios.put('/auth/invitation', {
    password,
    password_confirmation: passwordConfirmation,
    invitation_token: invitationToken,
  });
