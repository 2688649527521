export const Tabs = {
  variants: {
    'soft-rounded': {
      tab: {
        color: 'gray.700',
        border: '1px',
        borderColor: 'alpha.400',
        _selected: {
          boxShadow: 'none',
          bg: 'alpha.200',
          color: 'white',
          border: 'none',
        },
      },
    },
  },
  defaultProps: {
    variant: 'soft-rounded',
  },
};
