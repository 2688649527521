/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { exportLastMonth } from 'api/billing';
import { RESOURCE_STATUS } from 'utils/constants';

export const exportLastMonthThunk = createAsyncThunk(
  'billing/exportLastMonth',
  async () => {
    try {
      const { data, headers } = await exportLastMonth();
      return {
        billingCsv: data,
        fileData: headers['content-disposition'],
      };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    resourceStatus: RESOURCE_STATUS.IDLE,
    error: null,
  },
  extraReducers: {
    [exportLastMonthThunk.fulfilled]: state => {
      state.resourceStatus = RESOURCE_STATUS.READY;
      state.error = null;
    },
    [exportLastMonthThunk.pending]: state => {
      state.resourceStatus = RESOURCE_STATUS.LOADING;
      state.error = null;
    },
    [exportLastMonthThunk.rejected]: (state, action) => {
      state.resourceStatus = RESOURCE_STATUS.ERROR;
      state.error = action.error;
    },
  },
});

export default billingSlice.reducer;
