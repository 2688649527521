/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */

import React from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { initializeStore } from '../stores/store';

const isServer = typeof window === 'undefined';
const __REDUX_STORE__ = '__REDUX_STORE__';

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__REDUX_STORE__]) {
    window[__REDUX_STORE__] = initializeStore(initialState);
  }
  return window[__REDUX_STORE__];
}

const withReduxStore = App =>
  class AppWithRedux extends React.Component {
    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore();

      // Provide the store to getInitialProps of pages
      // eslint-disable-next-line no-param-reassign
      appContext.ctx.reduxStore = reduxStore;

      let appProps = {};
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext);
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState(),
      };
    }

    constructor(props) {
      super(props);
      this.reduxStore = getOrCreateStore(props.initialReduxState);
    }

    render() {
      let persistor;

      if (!isServer) {
        persistor = persistStore(this.reduxStore);
      }

      const app = <App {...this.props} reduxStore={this.reduxStore} />;

      return (
        <Provider store={this.reduxStore}>
          {isServer ? (
            app
          ) : (
            <PersistGate loading={null} persistor={persistor}>
              {app}
            </PersistGate>
          )}
        </Provider>
      );
    }
  };

export default withReduxStore;
