export const Button = {
  baseStyle: {
    borderRadius: 9999,
    borderWidth: 1,
    fontWeight: 700,
    paddingX: '24px',
    paddingY: '8px',
  },
  sizes: {
    xs: {
      minWidth: '102px',
      height: '40px',
      fontSize: 'md',
    },
    sm: {
      minWidth: '128px',
      height: '40px',
      fontSize: 'md',
    },
    md: {
      minWidth: '166px',
      height: '40px',
      fontSize: 'md',
    },
  },
  variants: {
    primary: {
      background: 'alpha.500',
      color: 'white',
    },
    secondary: {
      background: 'transparent',
      borderColor: 'alpha.400',
      color: 'alpha.400',
    },
    cancel: {
      background: 'transparent',
      borderColor: 'alpha.700',
      color: 'alpha.700',
    },
    outline: {
      background: 'transparent',
      borderWidth: 2,
      borderColor: 'alpha.500',
      color: 'alpha.500',
      _hover: {
        background: 'alpha.50',
      },
      _active: {
        background: 'alpha.100',
      },
      _disabled: {
        opacity: 0.4,
      },
    },
    ghost: {
      background: 'transparent',
      borderWidth: 0,
      color: 'alpha.500',
      _hover: {
        background: 'alpha.50',
      },
      _active: {
        background: 'alpha.100',
      },
      _disabled: {
        opacity: 0.4,
      },
    },
    'alpha-solid': {
      fontWeight: '700',
      color: 'white',
      background: 'alpha.500',
      _hover: {
        background: 'alpha.600',
        _disabled: {
          background: 'alpha.500',
        },
      },
      _focus: {
        background: 'alpha.700',
      },
      _disabled: {
        background: 'alpha.500',
        opacity: 0.4,
      },
    },
    link: {
      fontSize: 'md',
      fontWeight: '700',
      background: 'transparent',
      color: 'alpha.500',
      borderColor: 'transparent',
      _hover: {
        color: 'alpha.600',
        textDecoration: 'underline',
      },
      _active: {
        color: 'alpha.700',
      },
    },
  },
  defaultProps: {
    variant: 'alpha-solid',
    size: 'md',
  },
};
