/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';
import {
  createZone,
  fetchZonesByCompany,
  updateZone,
  deleteZone,
} from 'api/zones';

export const fetchAllZonesThunk = createAsyncThunk(
  'zones/fetchAllZones',
  async ({ companyId }) => {
    const { data, metadata } = await fetchZonesByCompany({
      companyId,
    });
    return {
      data,
      metadata,
    };
  },
);

export const fetchZonesThunk = createAsyncThunk(
  'zones/fetchZones',
  async ({ companyId, search, alarm }) => {
    const { data, metadata } = await fetchZonesByCompany({
      companyId,
      search,
      alarm,
    });
    return {
      data,
      metadata,
    };
  },
);

export const createZoneThunk = createAsyncThunk(
  'zones/createZone',
  async ({ params }) => {
    try {
      const response = await createZone(params);
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  },
);

export const updateZoneThunk = createAsyncThunk(
  'zones/updateZone',
  async ({ params }) => {
    try {
      const response = await updateZone(params);
      return response;
    } catch (error) {
      throw new Error(error?.response?.data?.message);
    }
  },
);

export const deleteZoneThunk = createAsyncThunk(
  'zones/deleteZone',
  async ({ zoneId }) => {
    try {
      const { data } = await deleteZone({ id: zoneId });

      return data;
    } catch (error) {
      const { response } = error;

      if (response?.data?.message) throw new Error(response.data.message);

      throw error;
    }
  },
);

const zonesSlice = createSlice({
  name: 'zones',
  initialState: {
    allCompanyZones: {
      items: [],
    },
    companyZones: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    createZone: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    updateZone: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    deleteZone: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  reducers: {
    removeAllCompanyZones(state) {
      state.allCompanyZones.items = [];
    },
  },
  extraReducers: {
    [fetchAllZonesThunk.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.allCompanyZones = {
        items: data.data,
        resourceStatus: RESOURCE_STATUS.READY,
        alarmsCount: data.metadata,
      };
    },
    [fetchAllZonesThunk.pending]: state => {
      state.allCompanyZones.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAllZonesThunk.rejected]: state => {
      state.allCompanyZones.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchZonesThunk.fulfilled]: (state, action) => {
      const { data } = action?.payload;
      state.companyZones = {
        items: data.data,
        resourceStatus: RESOURCE_STATUS.READY,
        alarmsCount: data.metadata,
      };

      if (state.allCompanyZones.items.length === 0) {
        state.allCompanyZones.items = data.data;
      }
    },
    [fetchZonesThunk.pending]: state => {
      state.companyZones.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchZonesThunk.rejected]: state => {
      state.companyZones.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [createZoneThunk.fulfilled]: state => {
      state.createZone.resourceStatus = RESOURCE_STATUS.READY;
      state.createZone.error = null;
    },
    [createZoneThunk.pending]: state => {
      state.createZone.error = null;
      state.createZone.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [createZoneThunk.rejected]: state => {
      state.createZone.resourceStatus = RESOURCE_STATUS.ERROR;
      state.createZone.error = state.error;
    },
    [deleteZoneThunk.fulfilled]: state => {
      state.deleteZone.resourceStatus = RESOURCE_STATUS.READY;
      state.deleteZone.error = null;
    },
    [deleteZoneThunk.pending]: state => {
      state.deleteZone.error = null;
      state.deleteZone.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deleteZoneThunk.rejected]: state => {
      state.deleteZone.resourceStatus = RESOURCE_STATUS.ERROR;
      state.deleteZone.error = state.error;
    },
    [updateZoneThunk.fulfilled]: state => {
      state.updateZone.resourceStatus = RESOURCE_STATUS.READY;
      state.updateZone.error = null;
    },
    [updateZoneThunk.pending]: state => {
      state.updateZone.error = null;
      state.updateZone.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updateZoneThunk.rejected]: state => {
      state.updateZone.resourceStatus = RESOURCE_STATUS.ERROR;
      state.updateZone.error = state.error;
    },
  },
});

const getZonesSlice = state => state.zones;

export const getZonesByCompany = createSelector(
  getZonesSlice,
  state => state.companyZones,
);

export const getAllZonesByCompany = createSelector(
  getZonesSlice,
  state => state.allCompanyZones,
);

export const { removeAllCompanyZones } = zonesSlice.actions;

export default zonesSlice.reducer;
