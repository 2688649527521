import axios from './axios';
import axiosDownloadable from './axiosDownloadable';

const authHeaderKeys = ['uid', 'client', 'access-token'];

export const setAuthHeaders = headers => {
  authHeaderKeys.forEach(key => setAuthHeader(key, headers[key]));
};

export const setCurrentCompanyId = companyId => {
  localStorage.setItem('currentCompanyId', companyId);
};

export const removeAuthHeaders = () => {
  authHeaderKeys.forEach(key => delete axios.defaults.headers.common[key]);
};

export const persistAuthHeaders = headers => {
  authHeaderKeys.forEach(key => persistAuthHeader(key, headers[key]));
};

export const getPersistedAuthHeaders = () => {
  const headers = {};
  authHeaderKeys.forEach(key => {
    headers[key] = localStorage.getItem(key);
  });
  return headers;
};

export const getCurrentCompanyId = () =>
  localStorage.getItem('currentCompanyId');

export const removePersistedAuthHeaders = () => {
  authHeaderKeys.forEach(key => localStorage.removeItem(key));
};

export const removeCurrentCompanyId = () => {
  localStorage.removeItem('currentCompanyId');
};

const persistAuthHeader = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
  }
};

const setAuthHeader = (key, value) => {
  if (value) {
    axios.defaults.headers.common[key] = value;
    axiosDownloadable.defaults.headers.common[key] = value;
  }
};
