import axiosDownloadable from 'lib/axiosDownloadable';
import axios from 'lib/axios';

export const fetchSnapshots = ({
  search,
  year,
  month,
  page,
  perPage,
  includeOpen,
}) =>
  axios.get('/invoicing/snapshots', {
    params: { search, year, month, page, per_page: perPage, includeOpen },
  });

export const fetchInvoiceDetail = ({
  snapshotId,
  page,
  perPage,
  search,
  noMessagesReceived,
}) =>
  axios.get(`/invoicing/snapshots/${snapshotId}/snapshot_items`, {
    params: {
      page,
      per_page: perPage,
      esn: search,
      noMessagesReceived,
    },
  });

export const applyInvoicingChanges = ({ invoiceId, snapshotItemsChanges }) =>
  axios.post(`invoicing/snapshots/${invoiceId}/update_snapshot_items`, {
    snapshot_items_changes: snapshotItemsChanges,
  });

export const closeInvoicingSnapshot = ({ year, month }) =>
  axios.post('/invoicing/snapshots/close', { year, month });

export const updateSnapshotAdjustment = ({ id, adjustment }) =>
  axios.put(`/invoicing/snapshots/${id}`, { adjustment });

export const downloadCsv = ({ month, year }) =>
  axiosDownloadable.get('/invoicing/invoices/csv', {
    params: { month, year },
  });

export const sendInvoicingReportsToRecipientsByIds = ({ snapshotIds }) =>
  axios.post('invoicing/snapshots/send_invoice_reports', {
    snapshot_ids: snapshotIds,
  });
