import axios from 'axios';
import qs from 'qs';
import { CamelcaseSerializer, SnakecaseSerializer } from 'cerealizr';
import { signOutThunk, updateSessionExpired } from 'stores/currentUser';

export const deserializer = new CamelcaseSerializer();
export const serializer = new SnakecaseSerializer();

export const deserialize = data =>
  data && deserializer.serialize(JSON.parse(data));

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 35000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  transformResponse: [deserialize],
  paramsSerializer: params =>
    qs.stringify(serializer.serialize(params), { arrayFormat: 'brackets' }),
});

export const setUpInterceptor = dispatch => {
  api.interceptors.response.use(
    response => response,
    error => {
      const { status } = error.response;
      if (status === 401) {
        dispatch(updateSessionExpired(true));
        dispatch(signOutThunk());
      }
      return Promise.reject(error);
    },
  );
};

export const { defaults: axiosDefaults } = axios;

export default api;
