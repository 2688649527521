import axios, { axiosDefaults, serializer } from 'lib/axios';
import axiosDownloadable from 'lib/axiosDownloadable';

export const fetchMonitor = id => axios.get(`/tanks/${id}`);

export const fetchMonitors = ({
  orderBy,
  orderDirection,
  page,
  search,
  currentCompanyId,
  perPage,
  zoneIds,
  alarmFilter,
  userId,
  includeInactive,
}) =>
  axios.get('/tanks', {
    params: {
      orderBy,
      orderDirection,
      page,
      search,
      current_company_id: currentCompanyId,
      per_page: perPage,
      ...(zoneIds && { zone_ids: [zoneIds] }),
      alarm: alarmFilter,
      with_alarms_count: true,
      userId,
      include_inactive: includeInactive,
    },
  });

export const fetchMapMonitors = ({
  orderBy,
  search,
  currentCompanyId,
  zoneIds,
  alarmFilter,
  fillLevelLower,
  fillLevelUpper,
  includeInactive,
}) => {
  return axios.get('/map/tanks', {
    params: {
      orderBy,
      search,
      current_company_id: currentCompanyId,
      zone_ids: zoneIds,
      alarm: alarmFilter,
      fill_level_lower: fillLevelLower,
      fill_level_upper: fillLevelUpper,
      include_inactive: includeInactive,
    },
  });
};

export const fetchReadings = ({
  id,
  startDate,
  endDate,
  page,
  perPage,
  readingType,
}) => {
  const params = { startDate, endDate, page, perPage };
  if (readingType !== 'all') {
    params.readingType = readingType;
  }
  return axios.get(`/tanks/${id}/readings`, {
    params,
  });
};

export const fetchLastReading = id =>
  axios.get(`/tanks/${id}/readings/last`).catch(() => ({ data: null }));

export const fetchAlarms = ({ id }) => axios.get(`/tanks/${id}/alarms`);

export const fetchSchedule = ({ id }) => axios.get(`/tanks/${id}/schedule`);

export const fetchLevels = ({
  id,
  startDate,
  endDate,
  page,
  perPage,
  orderDirection,
  orderBy,
}) =>
  axios.get(`/tanks/${id}/tank_levels`, {
    params: {
      startDate,
      endDate,
      page,
      per_page: perPage,
      order_direction: orderDirection,
      orderBy,
    },
  });

export const fetchAllTankLevels = ({
  id,
  startDate,
  endDate,
  page,
  perPage,
  orderDirection,
  orderBy,
}) =>
  axios.get(`/tanks/${id}/tank_levels/all_tank_levels`, {
    params: {
      startDate,
      endDate,
      page,
      per_page: perPage,
      order_direction: orderDirection,
      orderBy,
    },
  });

export const fetchFillLevels = ({
  id,
  startDate,
  endDate,
  page,
  orderBy,
  orderDirection,
}) =>
  axios.get(`/tanks/${id}/fill_events`, {
    params: { startDate, endDate, page, per_page: 10, orderBy, orderDirection },
  });

export const downloadLevelHistoryCsv = ({ startDate, endDate, id }) =>
  axiosDownloadable.get(`/tanks/${id}/tank_levels/download`, {
    params: { start_date: startDate, end_date: endDate },
  });

export const downloadFillLevelCsv = ({ startDate, endDate, id }) =>
  axiosDownloadable.get(`/tanks/${id}/fill_events/csv`, {
    params: { start_date: startDate, end_date: endDate },
  });

export const fetchGeometries = () =>
  axios.get('/tanks/geometry', {
    transformResponse: axiosDefaults.transformResponse,
  });

export const updateMonitor = ({ id, monitor }) =>
  axios.put(`/tanks/${id}`, { tank: serializer.serialize(monitor) });

export const createAlarm = ({ id, alarm }) =>
  axios.post(`/tanks/${id}/alarms`, {
    alarm: serializer.serialize(alarm),
  });

export const updateAlarms = ({ id, alarms, recipientIds }) => {
  return axios.put(`/tanks/${id}/alarms`, {
    alarms: serializer.serialize(alarms),
    recipientIds,
  });
};

export const updateAlarm = ({ id, alarm }) =>
  axios.put(`/tanks/${id}/alarms/${alarm.id}`, {
    alarm: serializer.serialize(alarm),
  });

export const deleteAlarm = ({ id, alarm }) =>
  axios.delete(`/tanks/${id}/alarms/${alarm.id}`);

export const updateSchedule = ({ id, schedule }) =>
  axios.put(`/tanks/${id}/schedule`, {
    schedule: serializer.serialize(schedule),
  });

export const calibrateTankMonitor = ({ tankMonitorId, calibrationData }) =>
  axios.post(
    `/tank_monitors/catcon/${tankMonitorId}/calibrate`,
    calibrationData,
  );

export const setUpGaugeReader = ({ tankMonitorId, gaugeType }) =>
  axios.post(`/tank_monitors/catcon/${tankMonitorId}/set_up_gauge_monitor`, {
    gauge_type: gaugeType,
  });

export const downloadMonitor = ({
  id,
  alarm,
  search,
  zoneId,
  currentCompanyId,
  includeInactive,
}) =>
  axiosDownloadable.get(`/tanks/download`, {
    params: {
      alarm,
      search,
      ...(id && { ids: [id] }),
      ...(zoneId && { zone_ids: [zoneId] }),
      current_company_id: currentCompanyId,
      include_inactive: includeInactive,
    },
  });

export const bulkMonitorsUpdate = ({ companyId, ids }) =>
  axios.post('/tanks/company_bulk_update', {
    ids,
    company_id: companyId,
  });

export const bulkMonitorsZoneUpdate = ({ zoneId, currentCompanyId, ids }) =>
  axios.post('/tanks/zone_bulk_update', {
    ids,
    current_company_id: currentCompanyId,
    zone_id: zoneId,
  });

export const deleteLevel = ({ id, levelId }) =>
  axios.delete(`/tanks/${id}/tank_levels/${levelId}`);

export const subscribeToMonitor = ({ id }) =>
  axios.post(`/tanks/${id}/subscription`);

export const unsubscribeToMonitor = ({ id }) =>
  axios.delete(`/tanks/${id}/subscription`);

export const fetchAllMonitorsReadings = ({
  startDate,
  endDate,
  esn,
  orderBy,
  orderDirection,
  readingType,
  page,
  perPage,
}) =>
  axios.get(`/readings`, {
    params: {
      startDate,
      endDate,
      esn,
      orderBy,
      orderDirection,
      readingType,
      page,
      perPage,
    },
  });

export const fetchAlarmsCount = ({
  search,
  companyId,
  zoneIds,
  includeInactive,
}) =>
  axios.get(`/tanks/alarms_count`, {
    params: {
      search,
      companyId,
      ...(zoneIds && { zone_ids: [zoneIds] }),
      include_inactive: includeInactive,
    },
  });
