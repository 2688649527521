import { createReducer } from '@reduxjs/toolkit';
import { setAuthHeaders, persistAuthHeaders } from 'lib/auth';

export const authReducer = createReducer({}, builder => {
  builder.addMatcher(
    action => action.payload && action.payload.headers,
    (state, action) => {
      setAuthHeaders(action.payload.headers);
      persistAuthHeaders(action.payload.headers);
    },
  );
});
