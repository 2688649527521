import axios from 'lib/axios';

export const fetchPricePlans = ({ page, perPage, orderBy, orderDirection }) => {
  return axios.get('/pricing-plans', {
    params: { page, per_page: perPage, orderBy, orderDirection },
  });
};

export const createPricePlan = ({ pricingPlan }) => {
  return axios.post('/pricing-plans', { pricing_plan: pricingPlan });
};

export const fetchPricePlan = id => {
  return axios.get(`/pricing-plans/${id}`);
};

export const updatePricePlan = ({ pricingPlan, id }) => {
  return axios.put(`/pricing-plans/${id}`, { pricing_plan: pricingPlan });
};

export const deletePricePlan = id => axios.delete(`/pricing-plans/${id}`);
