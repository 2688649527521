import axios, { serializer } from 'lib/axios';

export const fetchCompanies = ({
  orderBy,
  orderDirection,
  page,
  search,
  pricePlanName,
  perPage,
}) =>
  axios.get('/companies', {
    params: {
      orderBy,
      orderDirection,
      page,
      name: search,
      pricing_plan_name: pricePlanName,
      per_page: perPage,
    },
  });

export const postCompany = ({ company }) =>
  axios.post('/companies', { company: serializer.serialize(company) });

export const postExistentCompany = ({ id, company }) =>
  axios.put(`/companies/${id}`, { company: serializer.serialize(company) });

export const deleteCompany = ({ id }) => axios.delete(`/companies/${id}`);

export const activateCompany = ({ id }) =>
  axios.post(`/companies/${id}/activate`);

export const deactivateCompany = ({ id }) =>
  axios.post(`/companies/${id}/deactivate`);

export const downloadPdf = ({ month, year, companyId }) =>
  axios.post(`/companies/${companyId}/invoicing_report`, {
    month,
    year,
  });
