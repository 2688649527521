import axios from 'lib/axios';

export const fetchUsers = ({
  page,
  perPage,
  search,
  orderBy,
  orderDirection,
  companyId,
  tankId,
}) =>
  axios.get('/users', {
    params: {
      page,
      per_page: perPage,
      search,
      orderBy,
      orderDirection,
      companyId,
      tankId,
    },
  });
export const fetchUser = id => axios.get(`/users/${id}`);
export const createUser = ({ user }) => axios.post('/users', { user });
export const updateUser = ({ user }) =>
  axios.put(`/users/${user.id}`, { user });
export const deleteUser = id => axios.delete(`/users/${id}`);
