export const Text = {
  baseStyle: {
    color: 'gray.700',
  },
  variants: {
    heading4xl: {
      fontSize: '4xl',
      fontWeight: '700',
    },
    heading3xl: {
      fontSize: '3xl',
      fontWeight: '700',
      lineHeight: '36px',
    },
    heading2xl: {
      fontSize: '2xl',
      fontWeight: '700',
    },
    headingXl: {
      fontSize: 'xl',
      fontWeight: '700',
      lineHeight: '30px',
    },
    headingLg: {
      fontSize: 'lg',
      fontWeight: '700',
      lineHeight: '27px',
    },
    headingMd: {
      fontSize: 'md',
      fontWeight: '700',
      lineHeight: '24px',
    },
    headingSm: {
      fontSize: 'sm',
      fontWeight: '700',
    },
    headingXs: {
      fontSize: 'xs',
      fontWeight: '700',
      lineHeight: '18px',
    },
    text4Xl: {
      fontSize: '4xl',
      fontWeight: '400',
    },
    textXl: {
      fontSize: 'xl',
      fontWeight: '400',
    },
    textMd: {
      fontSize: 'md',
      fontWeight: '400',
      lineHeight: '24px',
    },
    textSm: {
      fontSize: 'sm',
      fontWeight: '400',
    },
    textXs: {
      fontSize: 'xs',
      fontWeight: '400',
    },
  },
};
