import NextI18Next from 'next-i18next';
import path from 'path';

const languages = {
  en: {
    name: 'English',
    code: 'en',
  },
  es: {
    name: 'Spanish',
    code: 'es',
  },
  fr: {
    name: 'French',
    code: 'fr',
  },
  // pt: {
  //   name: 'Portuguese',
  //   code: 'pt',
  // },
};

const i18n = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: Object.values(languages).map(l => l.code),
  localePath: path.resolve('./public/static/locales'),
  preload: [languages],
  fallbackLng: 'en',
});

export default i18n;

export { languages };
