/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';
import {
  createCompanyDiscount,
  deleteCompanyDiscounts,
  fetchDiscountsByCompany,
  updateCompanyDiscounts,
} from 'api/companyDiscounts';

export const fetchCompanyDiscountsThunk = createAsyncThunk(
  'companyDiscounts/fetchCompaniesDiscounts',
  async ({ companyId }) => {
    const response = await fetchDiscountsByCompany({ companyId });
    return response;
  },
);

export const createCompanyDiscountThunk = createAsyncThunk(
  'companyDiscounts/createCompanyDiscount',
  async ({ companyDiscount }) => {
    const response = await createCompanyDiscount({ companyDiscount });
    return response;
  },
);

export const updateCompanyDiscountsThunk = createAsyncThunk(
  'companyDiscounts/updateCompanyDiscounts',
  async ({ companyDiscounts }) => {
    const response = await updateCompanyDiscounts({ companyDiscounts });
    return response;
  },
);

export const deleteCompanyDiscountsThunk = createAsyncThunk(
  'companyDiscounts/deleteCompanyDiscounts',
  async ({ companyDiscountsIds }) => {
    const response = await deleteCompanyDiscounts({ companyDiscountsIds });
    return response;
  },
);

const companyDiscountsSlice = createSlice({
  name: 'companyDiscounts',
  initialState: {
    companyDiscounts: {
      value: [],
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    createCompanyDiscount: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    updateCompanyDiscounts: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    deleteCompanyDiscounts: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  extraReducers: {
    [fetchCompanyDiscountsThunk.fulfilled]: (state, action) => {
      state.companyDiscounts.value = action.payload.data;
      state.companyDiscounts.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchCompanyDiscountsThunk.pending]: state => {
      state.companyDiscounts.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchCompanyDiscountsThunk.rejected]: state => {
      state.companyDiscounts.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [createCompanyDiscountThunk.fulfilled]: state => {
      state.createCompanyDiscount.error = null;
      state.createCompanyDiscount.resourceStatus = RESOURCE_STATUS.READY;
    },
    [createCompanyDiscountThunk.pending]: state => {
      state.createCompanyDiscount.error = null;
      state.createCompanyDiscount.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [createCompanyDiscountThunk.rejected]: (state, action) => {
      state.createCompanyDiscount.error = action.error;
      state.createCompanyDiscount.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [updateCompanyDiscountsThunk.fulfilled]: state => {
      state.updateCompanyDiscounts.error = null;
      state.updateCompanyDiscounts.resourceStatus = RESOURCE_STATUS.READY;
    },
    [updateCompanyDiscountsThunk.pending]: state => {
      state.updateCompanyDiscounts.error = null;
      state.updateCompanyDiscounts.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updateCompanyDiscountsThunk.rejected]: (state, action) => {
      state.updateCompanyDiscounts.error = action.error;
      state.updateCompanyDiscounts.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [deleteCompanyDiscountsThunk.fulfilled]: state => {
      state.deleteCompanyDiscounts.error = null;
      state.deleteCompanyDiscounts.resourceStatus = RESOURCE_STATUS.READY;
    },
    [deleteCompanyDiscountsThunk.pending]: state => {
      state.deleteCompanyDiscounts.error = null;
      state.deleteCompanyDiscounts.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deleteCompanyDiscountsThunk.rejected]: (state, action) => {
      state.deleteCompanyDiscounts.error = action.error;
      state.deleteCompanyDiscounts.resourceStatus = RESOURCE_STATUS.ERROR;
    },
  },
});

export const getCompanyDiscounts = state =>
  state.companyDiscounts.companyDiscounts;

export default companyDiscountsSlice.reducer;
