export const Select = {
  baseStyle: {
    field: {
      background: 'unset',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
};
