import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { Button } from './components/Button';
import { FormLabel } from './components/FormLabel';
import { Input } from './components/Input';
import { NumberInput } from './components/NumberInput';
import { Text } from './components/Text';
import { Select } from './components/Select';
import { Tabs } from './components/Tabs';

// Look at https://chakra-ui.com/docs/getting-started#add-custom-theme-optional to
// extend the theme
const themes = {
  default: extendTheme({
    colors: {
      gray: {
        50: '#F9FAFB',
        100: '#E2E5E9',
        200: '#C4CCD4',
        300: '#A7b2BE',
        400: '#8A99A8',
        500: '#6C8093',
        600: '#576675',
        700: '#414D58',
        800: '#2B333B',
        900: '#161A1D',
      },
      alpha: {
        50: '#D5E3EF',
        100: '#B6CEE5',
        200: '#7FA6CA',
        300: '#5588B9',
        400: '#2A6AA7',
        500: '#004D96',
        600: '#003D78',
        700: '#002E5A',
        800: '#001E3B',
        900: '#000F1D',
      },
      beta: {
        50: '#FAF7FD',
        100: '#E5D6F5',
        200: '#CCADEB',
        300: '#B285E0',
        400: '#995CD6',
        500: '#8033CC',
        600: '#6629A3',
        700: '#4D1F7A',
        800: '#331452',
        900: '#1A0A29',
      },
      epsilon: {
        50: '#FDF7F7',
        100: '#F5D6D6',
        200: '#EBADAD',
        300: '#E08585',
        400: '#D65C5C',
        500: '#CC3333',
        600: '#A32929',
        700: '#7A1F1F',
        800: '#521414',
        900: '#290A0A',
      },
      delta: {
        50: '#F7FAFD',
        100: '#D6E4F5',
        200: '#ADCAEB',
        300: '#85AFE0',
        400: '#5C95D6',
        500: '#337ACC',
        600: '#2962A3',
        700: '#1F497A',
        800: '#143152',
        900: '#0A1829',
      },
      zeta: {
        50: '#FEFBF6',
        100: '#FAEDD1',
        200: '#F5DBA3',
        300: '#F0C975',
        400: '#EBB747',
        500: '#E5A51A',
        600: '#B88414',
        700: '#8A630F',
        800: '#5C420A',
        900: '#2E2105',
      },
      gamma: {
        50: '#F7FCF9',
        100: '#D9F2E1',
        200: '#B2E5C3',
        300: '#8CD9A6',
        400: '#66CC88',
        500: '#40BF6A',
        600: '#339955',
        700: '#267340',
        800: '#1A4D2B',
        900: '#0D2615',
      },
    },
    fonts: {
      body: 'Titillium Web',
      heading: 'Titillium Web',
    },
    fontSizes: {
      '4xl': '36px',
      '3xl': '30px',
      '2xl': '24px',
      xl: '20px',
      lg: '18px',
      md: '16px',
      sm: '14px',
      xs: '12px',
      '2xs': '10px',
    },
    breakpoints: {
      xs: '0px',
      sm: '600px',
      md: '960px',
      lg: '1280px',
      xl: '1920px',
    },
    components: {
      Button,
      FormLabel,
      Input,
      Select,
      Text,
      NumberInput,
      Steps,
      Tabs,
    },
  }),
  kbcTheme: extendTheme({
    colors: {
      gray: {
        50: '#F9FAFB',
        100: '#E2E5E9',
        200: '#C4CCD4',
        300: '#A7b2BE',
        400: '#8A99A8',
        500: '#6C8093',
        600: '#576675',
        700: '#414D58',
        800: '#2B333B',
        900: '#161A1D',
      },
      alpha: {
        50: '#D5E3EF',
        100: '#B6CEE5',
        200: '#7FA6CA',
        300: '#63AAB1', // navbar hover
        400: '#2A6AA7',
        500: '#007681', // primary
        600: '#074f54', // button hover
        700: '#053538',
        800: '#013f42',
        900: '#000F1D',
      },
      beta: {
        50: '#FAF7FD',
        100: '#E5D6F5',
        200: '#CCADEB',
        300: '#B285E0',
        400: '#995CD6',
        500: '#8033CC',
        600: '#6629A3',
        700: '#4D1F7A',
        800: '#331452',
        900: '#1A0A29',
      },
      epsilon: {
        50: '#FDF7F7',
        100: '#F5D6D6',
        200: '#EBADAD',
        300: '#E08585',
        400: '#D65C5C',
        500: '#CC3333',
        600: '#A32929',
        700: '#7A1F1F',
        800: '#521414',
        900: '#290A0A',
      },
      delta: {
        50: '#F7FAFD',
        100: '#D6E4F5',
        200: '#ADCAEB',
        300: '#85AFE0',
        400: '#5C95D6',
        500: '#337ACC',
        600: '#2962A3',
        700: '#1F497A',
        800: '#143152',
        900: '#0A1829',
      },
      zeta: {
        50: '#FEFBF6',
        100: '#FAEDD1',
        200: '#F5DBA3',
        300: '#F0C975',
        400: '#EBB747',
        500: '#E5A51A',
        600: '#B88414',
        700: '#8A630F',
        800: '#5C420A',
        900: '#2E2105',
      },
      gamma: {
        50: '#F7FCF9',
        100: '#D9F2E1',
        200: '#B2E5C3',
        300: '#8CD9A6',
        400: '#66CC88',
        500: '#40BF6A',
        600: '#339955',
        700: '#267340',
        800: '#1A4D2B',
        900: '#0D2615',
      },
    },
    fonts: {
      body: 'Raleway',
      heading: 'Montserrat',
    },
    fontSizes: {
      '4xl': '34px',
      '3xl': '28px',
      '2xl': '22px',
      xl: '18px',
      lg: '16px',
      md: '14px',
      sm: '12px',
      xs: '10px',
      '2xs': '10px',
    },
    breakpoints: {
      xs: '0px',
      sm: '600px',
      md: '960px',
      lg: '1280px',
      xl: '1920px',
    },
    components: {
      Button,
      FormLabel,
      Input,
      Select,
      Text,
      NumberInput,
      Steps,
      Tabs,
    },
  }),
};
export default themes;
