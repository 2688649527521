import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  signIn,
  verifyToken,
  signOut,
  resetPassword,
  changePassword,
  setupAccount,
} from 'api/auth';
import {
  setAuthHeaders,
  getPersistedAuthHeaders,
  removeAuthHeaders,
  removePersistedAuthHeaders,
  setCurrentCompanyId,
  removeCurrentCompanyId,
} from 'lib/auth';
import { MESSAGES } from 'utils/constants';
/* eslint-disable no-param-reassign */

export const signInThunk = createAsyncThunk(
  'currentUser/signIn',
  async (data, { rejectWithValue }) => {
    try {
      const response = await signIn(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const verifyTokenThunk = createAsyncThunk(
  'currentUser/verifyToken',
  async (_, { rejectWithValue }) => {
    const headers = getPersistedAuthHeaders();
    setAuthHeaders(headers);
    try {
      if (headers?.uid === null) throw new Error('headers not present');
      const response = await verifyToken(headers);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const signOutThunk = createAsyncThunk(
  'currentUser/signOut',
  async () => {
    try {
      const response = await signOut();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const setupAccountThunk = createAsyncThunk(
  'currentUser/setupAccount',
  async ({ params }, { rejectWithValue }) => {
    try {
      const response = await setupAccount(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const resetPasswordThunk = createAsyncThunk(
  'currentUser/resetPassword',
  async data => {
    try {
      const params = {
        ...data,
        redirectUrl: `${window.location.origin}/change_password`,
      };
      const response = await resetPassword(params);
      return response;
    } catch (error) {
      const { response } = error;
      if (response?.errors) {
        throw new Error(response.error);
      }
      throw error;
    }
  },
);

export const changePasswordThunk = createAsyncThunk(
  'currentUser/changePassword',
  async ({ params }) => {
    try {
      const response = await changePassword(params);
      return response;
    } catch (error) {
      const { response } = error;
      if (response?.errors) {
        throw new Error(response.error);
      }
      throw error;
    }
  },
);

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    hasVerificationBeenAttempted: false,
    resetPassword: {
      successMessage: '',
      error: {},
    },
    changePassword: {
      successMessage: '',
      error: {},
    },
    setupAccount: {
      successMessage: '',
      error: null,
    },
    signOut: false,
    sessionExpired: false,
    url: null,
  },
  reducers: {
    updateCurrentUser(state, action) {
      state.user = {
        companyName: state.user.company,
        isSubTenant: state.user.isSubTenant,
        isCompanyAdminPartner: state.user.isCompanyAdminPartner,
        ...action.payload,
      };
    },
    updateSessionExpired(state, action) {
      state.sessionExpired = action.payload;
    },
    updateUrl(state, action) {
      state.url = action.payload;
    },
  },
  extraReducers: {
    [signInThunk.fulfilled]: (state, { payload }) => {
      state.user = payload.data;
      setCurrentCompanyId(state.user.companyId);
      state.signInError = null;
      state.signOut = false;
      state.sessionExpired = false;
    },
    [signInThunk.rejected]: (state, { payload }) => {
      const { data } = payload || {};
      const { errors } = data || {};
      state.signInError =
        errors && errors[0] ? errors[0] : MESSAGES.DEFAULT_ERROR;
    },
    [verifyTokenThunk.fulfilled]: (state, { payload }) => {
      state.hasVerificationBeenAttempted = true;
      state.user = payload.data;
    },
    [verifyTokenThunk.rejected]: state => {
      state.hasVerificationBeenAttempted = true;
    },
    [resetPasswordThunk.fulfilled]: (state, action) => {
      const { message } = action.payload.data || {};
      state.resetPassword.successMessage = message;
      state.resetPassword.error = null;
    },
    [resetPasswordThunk.rejected]: (state, action) => {
      state.resetPassword.error = action.error;
    },
    [changePasswordThunk.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};
      state.changePassword.successMessage = message;
      state.changePassword.error = null;
    },
    [changePasswordThunk.rejected]: (state, action) => {
      state.changePassword.error = action.error;
    },
    [setupAccountThunk.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};
      state.setupAccount.successMessage = message;
      state.setupAccount.error = null;
    },
    [setupAccountThunk.pending]: state => {
      state.setupAccount.error = null;
    },
    [setupAccountThunk.rejected]: (state, { payload }) => {
      const { data } = payload || {};
      const { errors } = data || {};
      state.setupAccount.error =
        errors && errors[0] ? errors[0] : MESSAGES.DEFAULT_ERROR;
    },
    [signOutThunk.fulfilled]: state => {
      removeAuthHeaders();
      removePersistedAuthHeaders();
      removeCurrentCompanyId();
      state.user = null;
      state.signOut = true;
    },
  },
});

export const {
  updateCurrentUser,
  updateSessionExpired,
  updateUrl,
} = currentUserSlice.actions;
export const getCurrentUser = state => state.currentUser.user;
export const getHasVerificationBeenAttempted = state =>
  state.currentUser.hasVerificationBeenAttempted;
export const getSignInError = state => state.currentUser.signInError;
export const getCurrentUserRole = state => state.currentUser.user.role;
export const getCurrentUserCompany = state => ({
  companyId: state.currentUser.user.companyId,
  companyName: state.currentUser.user.companyName,
  isSubTenant: state.currentUser.user.isSubTenant,
  isCompanyAdminPartner: state.currentUser.user.isCompanyAdminPartner,
});
export const getResetPasswordInfo = state => state.currentUser.resetPassword;
export const getSignUpInfo = state => state.currentUser.setupAccount;
export const hasUserSignedOut = state => state.currentUser.signOut;
export const hasSessionExpired = state => state.currentUser.sessionExpired;
export const getUrl = state => state.currentUser.url;
export default currentUserSlice.reducer;
