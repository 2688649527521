/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import {
  createPricePlan,
  fetchPricePlans,
  fetchPricePlan,
  updatePricePlan,
  deletePricePlan,
} from 'api/pricePlans';
import { RESOURCE_STATUS } from 'utils/constants';

export const createPricePlanThunk = createAsyncThunk(
  'pricePlans/createPricePlan',
  async ({ pricingPlan }) => {
    try {
      const { data } = await createPricePlan({ pricingPlan });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const fetchPricePlanThunk = createAsyncThunk(
  'pricePlans/fetchPricePlan',
  async ({ id }) => {
    const { data } = await fetchPricePlan(id);
    return { data };
  },
);

export const fetchPricePlansThunk = createAsyncThunk(
  'pricePlans/fetchPricePlans',
  async ({ page, perPage, orderBy, orderDirection }) => {
    const { data, headers } = await fetchPricePlans({
      page,
      perPage,
      orderBy,
      orderDirection,
    });
    return {
      pricePlans: data,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const fetchAllPricePlansThunk = createAsyncThunk(
  'pricePlans/fetchAllPricePlans',
  async () => {
    const { data, headers } = await fetchPricePlans({
      page: 1,
    });
    return {
      pricePlans: data,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const updatePricePlanThunk = createAsyncThunk(
  'pricePlans/updatePricePlan',
  async ({ pricingPlan, id }) => {
    try {
      const { data } = await updatePricePlan({ pricingPlan, id });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const deletePricePlanThunk = createAsyncThunk(
  'pricePlans/deletePricePlan',
  async ({ id }) => {
    try {
      const { data } = await deletePricePlan(id);
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

const pricePlansSlice = createSlice({
  name: 'pricePlans',
  initialState: {
    createPricePlan: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    pricePlans: {
      data: [],
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
      totalPages: null,
      totalCount: null,
    },
    allPricePlans: {
      data: [],
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
      totalPages: null,
      totalCount: null,
    },
    pricePlan: {
      data: {},
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    updatePricePlan: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    deletePricePlan: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  extraReducers: {
    [createPricePlanThunk.fulfilled]: state => {
      state.createPricePlan.error = null;
      state.createPricePlan.resourceStatus = RESOURCE_STATUS.READY;
    },
    [createPricePlanThunk.pending]: state => {
      state.createPricePlan.error = null;
      state.createPricePlan.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [createPricePlanThunk.rejected]: (state, action) => {
      state.createPricePlan.error = action.error;
      state.createPricePlan.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchPricePlansThunk.fulfilled]: (state, action) => {
      const { pricePlans, headers } = action.payload;
      state.pricePlans.error = null;
      state.pricePlans.resourceStatus = RESOURCE_STATUS.READY;
      state.pricePlans.totalPages = Number(headers.totalPages);
      state.pricePlans.totalCount = Number(headers.totalCount);
      state.pricePlans.data = pricePlans;
    },
    [fetchPricePlansThunk.rejected]: (state, action) => {
      state.pricePlans.error = action.error;
      state.pricePlans.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchPricePlansThunk.pending]: state => {
      state.pricePlans.error = null;
      state.pricePlans.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAllPricePlansThunk.fulfilled]: (state, action) => {
      const { pricePlans, headers } = action.payload;
      state.allPricePlans.error = null;
      state.allPricePlans.resourceStatus = RESOURCE_STATUS.READY;
      state.allPricePlans.totalPages = Number(headers.totalPages);
      state.allPricePlans.totalCount = Number(headers.totalCount);
      state.allPricePlans.data = pricePlans;
    },
    [fetchAllPricePlansThunk.rejected]: (state, action) => {
      state.allPricePlans.error = action.error;
      state.allPricePlans.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchAllPricePlansThunk.pending]: state => {
      state.allPricePlans.error = null;
      state.allPricePlans.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchPricePlanThunk.fulfilled]: (state, action) => {
      state.pricePlan.error = null;
      state.pricePlan.resourceStatus = RESOURCE_STATUS.READY;
      state.pricePlan.data = action?.payload?.data;
    },
    [fetchPricePlanThunk.rejected]: (state, action) => {
      state.pricePlan.error = action.error;
      state.pricePlan.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchPricePlanThunk.pending]: state => {
      state.pricePlan.error = null;
      state.pricePlan.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updatePricePlanThunk.fulfilled]: state => {
      state.updatePricePlan.error = null;
      state.updatePricePlan.resourceStatus = RESOURCE_STATUS.READY;
    },
    [updatePricePlanThunk.pending]: state => {
      state.updatePricePlan.error = null;
      state.updatePricePlan.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updatePricePlanThunk.rejected]: (state, action) => {
      state.updatePricePlan.error = action.error;
      state.updatePricePlan.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [deletePricePlanThunk.fulfilled]: state => {
      state.deletePricePlan.error = null;
      state.deletePricePlan.resourceStatus = RESOURCE_STATUS.READY;
    },
    [deletePricePlanThunk.pending]: state => {
      state.deletePricePlan.error = null;
      state.deletePricePlan.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deletePricePlanThunk.rejected]: (state, action) => {
      state.deletePricePlan.error = action.error;
      state.deletePricePlan.resourceStatus = RESOURCE_STATUS.ERROR;
    },
  },
});

const getPricePlansSlice = state => state.pricePlans;

export const getPricePlan = createSelector(
  getPricePlansSlice,
  state => state.pricePlan,
);

export const getPricePlans = createSelector(
  getPricePlansSlice,
  state => state.pricePlans,
);

export const getAllPricePlans = createSelector(
  getPricePlansSlice,
  state => state.allPricePlans,
);

export default pricePlansSlice.reducer;
