/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';

import {
  activateCompany,
  deactivateCompany,
  deleteCompany,
  downloadPdf,
  fetchCompanies,
  postCompany,
  postExistentCompany,
} from 'api/companies';

export const fetchCompaniesThunk = createAsyncThunk(
  'companies/fetchCompanies',
  async ({ orderBy, orderDirection, page, search, pricePlanName, perPage }) => {
    const { data, headers } = await fetchCompanies({
      search,
      page,
      orderBy,
      orderDirection,
      pricePlanName,
      perPage,
    });
    return {
      companies: data,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const saveCompany = createAsyncThunk(
  'companies/saveCompany',
  async ({ company }) => {
    const { data } = await postCompany({ company });
    return { company: data };
  },
);

export const editCompany = createAsyncThunk(
  'companies/editCompany',
  async ({ id, company }) => {
    try {
      const { data } = await postExistentCompany({ id, company });
      return { company: data };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const deleteCompanyThunk = createAsyncThunk(
  'companies/deleteCompany',
  async ({ id }) => {
    try {
      const { data } = await deleteCompany({ id });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const activateCompanyThunk = createAsyncThunk(
  'companies/activateCompany',
  async ({ id }) => {
    try {
      const { data } = await activateCompany({ id });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const deactivateCompanyThunk = createAsyncThunk(
  'companies/deactivateCompany',
  async ({ id }) => {
    try {
      const { data } = await deactivateCompany({ id });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const downloadCompanyPdfThunk = createAsyncThunk(
  'invoicing/downloadCompanyPdf',
  async ({ month, year, companyId }) => {
    try {
      const { data } = await downloadPdf({ month, year, companyId });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    list: [],
    totalPages: null,
    totalCount: null,
    resourceStatus: RESOURCE_STATUS.IDLE,
    editedCompany: {
      value: null,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    deleteCompany: {
      error: null,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    activateCompany: {
      error: null,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    deactivateCompany: {
      error: null,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    downloadPdf: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  extraReducers: {
    [fetchCompaniesThunk.fulfilled]: (state, action) => {
      const { companies, headers } = action.payload;
      state.list = companies;
      state.totalPages = Number(headers.totalPages);
      state.totalCount = Number(headers.totalCount);
      state.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchCompaniesThunk.pending]: state => {
      state.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchCompaniesThunk.rejected]: state => {
      state.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [saveCompany.fulfilled]: (state, action) => {
      const { company } = action.payload;
      state.editedCompany.value = company;
      state.editedCompany.resourceStatus = RESOURCE_STATUS.READY;
    },
    [saveCompany.pending]: state => {
      state.editedCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [saveCompany.rejected]: state => {
      state.editedCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [editCompany.fulfilled]: (state, action) => {
      const { company } = action.payload;
      state.editedCompany.value = company;
      state.editedCompany.resourceStatus = RESOURCE_STATUS.READY;
    },
    [editCompany.pending]: state => {
      state.editedCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [editCompany.rejected]: state => {
      state.editedCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [deleteCompanyThunk.fulfilled]: state => {
      state.deleteCompany.error = null;
      state.deleteCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deleteCompanyThunk.pending]: state => {
      state.deleteCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deleteCompanyThunk.rejected]: state => {
      state.deleteCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [activateCompanyThunk.fulfilled]: state => {
      state.activateCompany.error = null;
      state.activateCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [activateCompanyThunk.pending]: state => {
      state.activateCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [activateCompanyThunk.rejected]: state => {
      state.activateCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [deactivateCompanyThunk.fulfilled]: state => {
      state.deactivateCompany.error = null;
      state.deactivateCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deactivateCompanyThunk.pending]: state => {
      state.deactivateCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deactivateCompanyThunk.rejected]: state => {
      state.deactivateCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [downloadCompanyPdfThunk.fulfilled]: state => {
      state.downloadPdf.error = null;
      state.downloadPdf.resourceStatus = RESOURCE_STATUS.READY;
    },
    [downloadCompanyPdfThunk.pending]: state => {
      state.downloadPdf.error = null;
      state.downloadPdf.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [downloadCompanyPdfThunk.rejected]: (state, action) => {
      state.downloadPdf.error = action.error;
      state.downloadPdf.resourceStatus = RESOURCE_STATUS.ERROR;
    },
  },
});

export const companiesSelector = state => state.companies;
export const editedCompanySelector = state => state.companies.editedCompany;

export default companiesSlice.reducer;
