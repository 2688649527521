/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  applyInvoicingChanges,
  closeInvoicingSnapshot,
  downloadCsv,
  fetchInvoiceDetail,
  fetchSnapshots,
  sendInvoicingReportsToRecipients,
  sendInvoicingReportsToRecipientsByIds,
  updateSnapshotAdjustment,
} from 'api/invoicing';
import { RESOURCE_STATUS } from 'utils/constants';

export const fetchSnapshotsThunk = createAsyncThunk(
  'invoicing/fetchSnapshots',
  async ({ search, year, month, page, perPage, includeOpen }) => {
    try {
      const { data, headers } = await fetchSnapshots({
        search,
        year,
        month,
        page,
        perPage,
        includeOpen,
      });
      return {
        snapshots: data,
        headers: {
          totalPages: headers['total-pages'],
          totalCount: headers['total-count'],
        },
      };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const fetchInvoiceDetailsThunk = createAsyncThunk(
  'invoicing/fetchInvoiceDetails',
  async ({ snapshotId, search, page, perPage, noMessagesReceived }) => {
    try {
      const { data, headers } = await fetchInvoiceDetail({
        snapshotId,
        search,
        page,
        perPage,
        noMessagesReceived,
      });
      return {
        invoiceDetail: data,
        headers: {
          totalPages: headers['total-pages'],
          totalCount: headers['total-count'],
        },
      };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const applyInvoicingChangesThunk = createAsyncThunk(
  'invoicing/applyInvoicingChanges',
  async ({ invoiceId, snapshotItemsChanges }) => {
    try {
      const { data } = await applyInvoicingChanges({
        invoiceId,
        snapshotItemsChanges,
      });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const updateInvoicingAdjustmentThunk = createAsyncThunk(
  'invoicing/updateInvoicingAdjustment',
  async ({ id, snapshotAdjustment }) => {
    try {
      const { data } = await updateSnapshotAdjustment({
        id,
        adjustment: snapshotAdjustment,
      });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const closeInvoicingSnapshotThunk = createAsyncThunk(
  'invoicing/closeInvoicingSnapshot',
  async ({ month, year }) => {
    try {
      await closeInvoicingSnapshot({
        month,
        year,
      });
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const downloadInvoicesThunk = createAsyncThunk(
  'invoicing/downloadInvoices',
  async ({ month, year }) => {
    try {
      const { data, headers } = await downloadCsv({ month, year });
      return {
        invoicingCsv: data,
        fileData: headers['content-disposition'],
      };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const sendInvoicingReportsToRecipientsThunk = createAsyncThunk(
  'invoicing/sendInvoicingReportsToRecipients',
  async ({ month, year }) => {
    try {
      await sendInvoicingReportsToRecipients({ month, year });
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const sendSelectedInvoicingReportsThunk = createAsyncThunk(
  'invoicing/sendSelectedInvoicingReports',
  async ({ snapshotIds }) => {
    try {
      await sendInvoicingReportsToRecipientsByIds({ snapshotIds });
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

const invoicingSlice = createSlice({
  name: 'invoicing',
  initialState: {
    invoiceDateSelected: {
      date: null,
    },
    snapshots: {
      data: [],
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
      totalPages: null,
      totalCount: null,
    },
    invoiceDetail: {
      data: [],
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
      totalPages: null,
      totalCount: null,
    },
    invoicingChanges: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    closeInvoices: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    invoicingAdjustment: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    downloadCsv: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    sendInvoicingReportsToRecipients: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  reducers: {
    setInvoiceDateSelected(state, action) {
      state.invoiceDateSelected.date = action.payload;
    },
  },
  extraReducers: {
    [fetchSnapshotsThunk.fulfilled]: (state, action) => {
      const { snapshots, headers } = action.payload;
      state.snapshots.error = null;
      state.snapshots.resourceStatus = RESOURCE_STATUS.READY;
      state.snapshots.totalPages = Number(headers.totalPages);
      state.snapshots.totalCount = Number(headers.totalCount);
      state.snapshots.data = snapshots;
    },
    [fetchSnapshotsThunk.rejected]: (state, action) => {
      state.snapshots.error = action.error;
      state.snapshots.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchSnapshotsThunk.pending]: state => {
      state.snapshots.error = null;
      state.snapshots.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchInvoiceDetailsThunk.fulfilled]: (state, action) => {
      const { invoiceDetail, headers } = action.payload;
      state.invoiceDetail.error = null;
      state.invoiceDetail.resourceStatus = RESOURCE_STATUS.READY;
      state.invoiceDetail.totalPages = Number(headers.totalPages);
      state.invoiceDetail.totalCount = Number(headers.totalCount);
      state.invoiceDetail.data = invoiceDetail;
    },
    [fetchInvoiceDetailsThunk.rejected]: (state, action) => {
      state.invoiceDetail.error = action.error;
      state.invoiceDetail.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchInvoiceDetailsThunk.pending]: state => {
      state.invoiceDetail.error = null;
      state.invoiceDetail.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [applyInvoicingChangesThunk.fulfilled]: state => {
      state.invoicingChanges.error = null;
      state.invoicingChanges.resourceStatus = RESOURCE_STATUS.READY;
    },
    [applyInvoicingChangesThunk.pending]: state => {
      state.invoicingChanges.error = null;
      state.invoicingChanges.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [applyInvoicingChangesThunk.rejected]: (state, action) => {
      state.invoicingChanges.error = action.error;
      state.invoicingChanges.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [closeInvoicingSnapshotThunk.fulfilled]: state => {
      state.closeInvoices.error = null;
      state.closeInvoices.resourceStatus = RESOURCE_STATUS.READY;
    },
    [closeInvoicingSnapshotThunk.pending]: state => {
      state.closeInvoices.error = null;
      state.closeInvoices.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [closeInvoicingSnapshotThunk.rejected]: (state, action) => {
      state.closeInvoices.error = action.error;
      state.closeInvoices.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [updateInvoicingAdjustmentThunk.fulfilled]: state => {
      state.invoicingAdjustment.error = null;
      state.invoicingAdjustment.resourceStatus = RESOURCE_STATUS.READY;
    },
    [updateInvoicingAdjustmentThunk.pending]: state => {
      state.invoicingAdjustment.error = null;
      state.invoicingAdjustment.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [updateInvoicingAdjustmentThunk.rejected]: (state, action) => {
      state.invoicingAdjustment.error = action.error;
      state.invoicingAdjustment.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [downloadInvoicesThunk.fulfilled]: state => {
      state.downloadCsv.error = null;
      state.downloadCsv.resourceStatus = RESOURCE_STATUS.READY;
    },
    [downloadInvoicesThunk.pending]: state => {
      state.downloadCsv.error = null;
      state.downloadCsv.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [downloadInvoicesThunk.rejected]: (state, action) => {
      state.downloadCsv.error = action.error;
      state.downloadCsv.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [sendInvoicingReportsToRecipientsThunk.fulfilled]: state => {
      state.sendInvoicingReportsToRecipients.error = null;
      state.sendInvoicingReportsToRecipients.resourceStatus =
        RESOURCE_STATUS.READY;
    },
    [sendInvoicingReportsToRecipientsThunk.pending]: state => {
      state.sendInvoicingReportsToRecipients.error = null;
      state.sendInvoicingReportsToRecipients.resourceStatus =
        RESOURCE_STATUS.LOADING;
    },
    [sendInvoicingReportsToRecipientsThunk.rejected]: (state, action) => {
      state.sendInvoicingReportsToRecipients.error = action.error;
      state.sendInvoicingReportsToRecipients.resourceStatus =
        RESOURCE_STATUS.ERROR;
    },
  },
});

const getInvoicingSlice = state => state.invoicing;

export const getSnapshots = createSelector(
  getInvoicingSlice,
  state => state.snapshots,
);

export const getInvoiceDetails = createSelector(
  getInvoicingSlice,
  state => state.invoiceDetail,
);

export const getInvoiceDateSelected = createSelector(
  getInvoicingSlice,
  state => state.invoiceDateSelected.date,
);

export const { setInvoiceDateSelected } = invoicingSlice.actions;

export default invoicingSlice.reducer;
