import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { setUpInterceptor } from 'lib/axios';
import withReduxStore from '../lib/with-redux-store';
import i18n from '../lib/i18n';
import ThemeProvider from '../providers/ThemeProvider';

const {
  publicRuntimeConfig: { MAPBOX_ACCESS_TOKEN },
} = getConfig();
const { appWithTranslation } = i18n;

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

function ReactSPATemplate({ Component, pageProps }) {
  const router = useRouter();
  const dispatch = useDispatch();
  setUpInterceptor(dispatch);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: router.pathname,
      page_path: window.location.pathname,
      send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    });
  }, [router.pathname]);

  return (
    <ThemeProvider>
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

ReactSPATemplate.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.shape({}),
};

export default withReduxStore(appWithTranslation(ReactSPATemplate));
