import axios from 'lib/axios';

export const fetchProductsByCompany = ({ companyId }) => {
  return axios.get('/products', { params: { company_id: companyId } });
};

export const updateCompaniesProducts = ({ productsList }) => {
  return axios.put('/products/bulk_update', { products: productsList });
};

export const getProductTypes = () =>
  axios.get('/product_types', { params: { per_page: 100 } });
