/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';
import { fetchCompanies, getCompany } from 'api/company';
import { signInThunk } from 'stores/currentUser';
import { setCurrentCompanyId } from 'lib/auth';

export const fetchCompaniesList = createAsyncThunk(
  'company/fetchCompanies',
  async ({ search = null } = {}) => {
    const response = await fetchCompanies(search);
    return response;
  },
);

export const fetchCompany = createAsyncThunk(
  'company/fetchCompany',
  async id => {
    const response = await getCompany(id);
    return response;
  },
);

export const fetchCurrentCompany = createAsyncThunk(
  'company/fetchCurrentCompany',
  async id => {
    const response = await getCompany(id);
    return response;
  },
);

const companySlice = createSlice({
  name: 'company',
  initialState: {
    selectedCompany: {
      value: null,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    currentCompany: {
      value: null,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    companyList: [],
    resourceStatus: RESOURCE_STATUS.IDLE,
  },
  reducers: {
    updateCurrentCompany(state, action) {
      const { companyId } = action.payload;
      setCurrentCompanyId(companyId);
    },
  },
  extraReducers: {
    [fetchCompaniesList.fulfilled]: (state, action) => {
      state.companyList = action.payload.data;
      state.resourceStatus = RESOURCE_STATUS.READY;
    },
    [signInThunk.fulfilled]: (state, { payload }) => {
      state.companyId = payload.data.companyId;
    },
    [fetchCompaniesList.pending]: state => {
      state.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchCompaniesList.rejected]: state => {
      state.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchCompany.fulfilled]: (state, action) => {
      state.selectedCompany.value = action.payload.data;
      state.selectedCompany.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchCompany.pending]: state => {
      state.selectedCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchCompany.rejected]: state => {
      state.selectedCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchCurrentCompany.fulfilled]: (state, action) => {
      state.currentCompany.value = action.payload.data;
      state.currentCompany.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchCurrentCompany.pending]: state => {
      state.currentCompany.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchCurrentCompany.rejected]: state => {
      state.currentCompany.resourceStatus = RESOURCE_STATUS.ERROR;
    },
  },
});

export const { updateCurrentCompany } = companySlice.actions;
export const getCompanyList = state => state.company.companyList;
export const getSelectedCompany = state => state.company.selectedCompany;
export const getCurrentCompany = state => state.company.currentCompany;
export const getCompanyListWithStatus = state => ({
  companyList: state.company.companyList,
  resourceStatus: state.company.resourceStatus,
});

export default companySlice.reducer;
