import axios from 'lib/axios';

export const fetchDiscountsByCompany = ({ companyId }) => {
  return axios.get('/company_discounts', { params: { company_id: companyId } });
};

export const createCompanyDiscount = ({ companyDiscount }) => {
  return axios.post('/company_discounts', {
    company_discount: companyDiscount,
  });
};

export const updateCompanyDiscounts = ({ companyDiscounts }) => {
  return axios.put('/company_discounts/bulk_update', {
    company_discounts: companyDiscounts,
  });
};

export const deleteCompanyDiscounts = ({ companyDiscountsIds }) => {
  return axios.put('/company_discounts/bulk_delete', {
    company_discounts_ids: companyDiscountsIds,
  });
};
