/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';
import {
  fetchMonitor,
  fetchAlarms,
  fetchLevels,
  fetchReadings,
  fetchLastReading,
  calibrateTankMonitor,
  updateMonitor,
  createAlarm,
  updateAlarm,
  deleteAlarm,
  updateAlarms,
  fetchSchedule,
  updateSchedule,
  setUpGaugeReader,
  deleteLevel,
  unsubscribeToMonitor,
  subscribeToMonitor,
  fetchFillLevels,
  downloadFillLevelCsv,
  fetchMonitors,
  downloadLevelHistoryCsv,
  fetchAllTankLevels,
} from 'api/monitor';
import { sortById } from 'utils/sorting';

export const fetchMonitorThunk = createAsyncThunk(
  'monitor/fetchMonitor',
  async monitorId => {
    const { data } = await fetchMonitor(monitorId);
    return { data };
  },
);

export const fetchMonitorByEsnThunk = createAsyncThunk(
  'monitor/fetchMonitorByEsn',
  async esn => {
    const { data } = await fetchMonitors({
      search: esn,
      includeInactive: true,
    });
    return { data };
  },
);

export const fetchLevelsThunk = createAsyncThunk(
  'monitor/fetchLevels',
  async ({
    startDate,
    endDate,
    page,
    id,
    perPage,
    orderDirection,
    orderBy,
  }) => {
    const { data, headers } = await fetchLevels({
      id,
      startDate,
      endDate,
      page,
      perPage,
      orderDirection,
      orderBy,
    });
    return {
      data,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const fetchAllTankLevelsThunk = createAsyncThunk(
  'monitor/fetchAllTankLevels',
  async ({
    startDate,
    endDate,
    page,
    id,
    perPage,
    orderDirection,
    orderBy,
  }) => {
    const { data } = await fetchAllTankLevels({
      id,
      startDate,
      endDate,
      page,
      perPage,
      orderDirection,
      orderBy,
    });
    return {
      data,
    };
  },
);

export const fetchFillLevelsThunk = createAsyncThunk(
  'monitor/fetchFillLevels',
  async ({ startDate, endDate, page, id, orderBy, orderDirection }) => {
    const {
      data: { data, metadata },
      headers,
    } = await fetchFillLevels({
      id,
      startDate,
      endDate,
      page,
      orderBy,
      orderDirection,
    });
    return {
      data,
      metadata,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const downloadLevelHistoryThunk = createAsyncThunk(
  'monitor/downloadLevelHistory',
  async ({ startDate, endDate, id }) => {
    try {
      const { data } = await downloadLevelHistoryCsv({
        startDate,
        endDate,
        id,
      });
      return {
        data,
      };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const downloadFillLevelsThunk = createAsyncThunk(
  'monitor/downloadFillLevels',
  async ({ startDate, endDate, id }) => {
    try {
      const { data } = await downloadFillLevelCsv({
        startDate,
        endDate,
        id,
      });
      return {
        data,
      };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const fetchAlarmsThunk = createAsyncThunk(
  'monitor/fetchAlarms',
  async ({ id }) => {
    const response = await fetchAlarms({ id });

    return { alarms: response.data };
  },
);

export const fetchReadingsThunk = createAsyncThunk(
  'monitor/fetchReadings',
  async ({ startDate, endDate, page, perPage, readingType, id }) => {
    const { data, headers } = await fetchReadings({
      id,
      startDate,
      endDate,
      page,
      perPage,
      readingType,
    });

    return {
      data,
      headers: {
        totalPages: headers['total-pages'],
        totalCount: headers['total-count'],
      },
    };
  },
);

export const fetchLastReadingThunk = createAsyncThunk(
  'monitor/fetchLastReading',
  async id => {
    const { data } = await fetchLastReading(id);
    return data;
  },
);

export const calibrateTankMonitorThunk = createAsyncThunk(
  'monitor/calibrateTankMonitor',
  async payload => {
    try {
      const { data } = await calibrateTankMonitor(payload);
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const setupGaugeReaderThunk = createAsyncThunk(
  'monitor/setupGaugeReader',
  async payload => {
    const { data } = await setUpGaugeReader(payload);
    return data;
  },
);

export const updateMonitorThunk = createAsyncThunk(
  'monitor/updateMonitor',
  async ({ monitor, id }) => {
    try {
      const { data } = await updateMonitor({ monitor, id });
      return { data };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const createAlarmThunk = createAsyncThunk(
  'monitor/createAlarm',
  async ({ alarm, id }) => {
    try {
      const { data } = await createAlarm({ alarm, id });
      return { data };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const updateAlarmsThunk = createAsyncThunk(
  'monitor/updateAlarms',
  async ({ alarms, id, recipientIds }) => {
    try {
      const { data } = await updateAlarms({ alarms, id, recipientIds });
      return { data };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const updateAlarmThunk = createAsyncThunk(
  'monitor/updateAlarm',
  async ({ alarm, id }) => {
    try {
      const { data } = await updateAlarm({ alarm, id });
      return { data };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const deleteAlarmThunk = createAsyncThunk(
  'monitor/deleteAlarm',
  async ({ alarm, id }) => {
    try {
      const { data } = await deleteAlarm({ alarm, id });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const updateScheduleThunk = createAsyncThunk(
  'monitor/updateSchedule',
  async ({ schedule, id }) => {
    try {
      const { data } = await updateSchedule({ schedule, id });
      return { data };
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const fetchScheduleThunk = createAsyncThunk(
  'monitor/fetchSchedule',
  async ({ id }) => {
    const { data } = await fetchSchedule({ id });
    return { data };
  },
);

export const deleteLevelThunk = createAsyncThunk(
  'monitor/deleteLevel',
  async ({ id, levelId }) => {
    try {
      const { data } = await deleteLevel({ id, levelId });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const subscribeToMonitorThunk = createAsyncThunk(
  'monitor/subscribeToMonitor',
  async ({ id }) => {
    try {
      const { data } = await subscribeToMonitor({ id });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const unsubscribeToMonitorThunk = createAsyncThunk(
  'monitor/unsubscribeToMonitor',
  async ({ id }) => {
    try {
      const { data } = await unsubscribeToMonitor({ id });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

const monitorSlice = createSlice({
  name: 'monitor',
  initialState: {
    resourceStatus: RESOURCE_STATUS.IDLE,
    details: {},
    levels: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    allTankLevels: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    fillLevels: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
      stats: {},
    },
    fillLevelsExport: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
      value: {},
    },
    levelHistoryExport: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
      value: {},
    },
    alarms: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    alarm: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    readings: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    schedule: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      items: [],
    },
    lastReading: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      value: null,
    },
    calibration: {
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    gaugeReader: {
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    deleteLevel: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    subscribeToMonitor: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    unsubscribeToMonitor: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  reducers: {
    setDownloadFillLevelsStatusIdle(downloadData) {
      downloadData.fillLevelsExport.resourceStatus = RESOURCE_STATUS.IDLE;
    },
    setDownloadLevelHistoryStatusIdle(downloadData) {
      downloadData.levelHistoryExport.resourceStatus = RESOURCE_STATUS.IDLE;
    },
  },
  extraReducers: {
    [fetchMonitorThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.details = data;
      state.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchMonitorThunk.pending]: state => {
      state.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchMonitorThunk.rejected]: state => {
      state.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchMonitorByEsnThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;
      const [monitor] = data;
      state.details = monitor;
      state.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchMonitorByEsnThunk.pending]: state => {
      state.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchMonitorByEsnThunk.rejected]: state => {
      state.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchLevelsThunk.fulfilled]: (state, action) => {
      const { data, headers } = action.payload;

      state.levels = {
        items: data,
        resourceStatus: RESOURCE_STATUS.READY,
        totalPages: Number(headers.totalPages),
        totalCount: Number(headers.totalCount),
      };
    },
    [fetchLevelsThunk.pending]: state => {
      state.levels.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchLevelsThunk.rejected]: state => {
      state.levels.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchAllTankLevelsThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.allTankLevels = {
        items: data,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchAllTankLevelsThunk.pending]: state => {
      state.allTankLevels.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAllTankLevelsThunk.rejected]: state => {
      state.allTankLevels.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchFillLevelsThunk.fulfilled]: (state, action) => {
      const { data, metadata, headers } = action.payload;

      state.fillLevels = {
        items: data,
        stats: metadata,
        resourceStatus: RESOURCE_STATUS.READY,
        totalPages: Number(headers.totalPages),
        totalCount: Number(headers.totalCount),
      };
    },
    [fetchFillLevelsThunk.pending]: state => {
      state.fillLevels.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchFillLevelsThunk.rejected]: state => {
      state.fillLevels.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [downloadLevelHistoryThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.levelHistoryExport.value = data;
      state.levelHistoryExport.error = null;
      state.levelHistoryExport.resourceStatus = RESOURCE_STATUS.READY;
    },
    [downloadLevelHistoryThunk.pending]: state => {
      state.levelHistoryExport.error = null;
      state.levelHistoryExport.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [downloadLevelHistoryThunk.rejected]: (state, action) => {
      state.levelHistoryExport.error = action.error;
      state.levelHistoryExport.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [downloadFillLevelsThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.fillLevelsExport.value = data;
      state.fillLevelsExport.error = null;
      state.fillLevelsExport.resourceStatus = RESOURCE_STATUS.READY;
    },
    [downloadFillLevelsThunk.pending]: state => {
      state.fillLevelsExport.error = null;
      state.fillLevelsExport.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [downloadFillLevelsThunk.rejected]: (state, action) => {
      state.fillLevelsExport.error = action.error;
      state.fillLevelsExport.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchAlarmsThunk.fulfilled]: (state, action) => {
      const { alarms } = action.payload;
      state.alarms = {
        items: sortById(alarms),
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchAlarmsThunk.pending]: state => {
      state.alarms.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchAlarmsThunk.rejected]: state => {
      state.alarms.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchScheduleThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.schedule = {
        value: data,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [fetchScheduleThunk.pending]: state => {
      state.schedule.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchScheduleThunk.rejected]: state => {
      state.schedule.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchReadingsThunk.fulfilled]: (state, action) => {
      const { data, headers } = action.payload;

      state.readings = {
        items: data,
        resourceStatus: RESOURCE_STATUS.READY,
        totalPages: Number(headers.totalPages),
        totalCount: Number(headers.totalCount),
      };
    },
    [fetchReadingsThunk.pending]: state => {
      state.readings.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchReadingsThunk.rejected]: state => {
      state.readings.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [updateMonitorThunk.fulfilled]: (state, action) => {
      const { data } = action.payload;

      state.details = data;
      state.resourceStatus = RESOURCE_STATUS.READY;
    },
    [updateMonitorThunk.pending]: state => {
      state.resourceStatus = RESOURCE_STATUS.PENDING;
    },
    [updateMonitorThunk.rejected]: state => {
      state.resourceStatus = RESOURCE_STATUS.REJECTED;
    },
    [createAlarmThunk.fulfilled]: state => {
      state.alarm.resourceStatus = RESOURCE_STATUS.READY;
      state.alarm.error = null;
    },
    [createAlarmThunk.pending]: state => {
      state.alarm.resourceStatus = RESOURCE_STATUS.PENDING;
    },
    [createAlarmThunk.rejected]: (state, action) => {
      state.alarm.resourceStatus = RESOURCE_STATUS.REJECTED;
      state.alarm.error = action.error;
    },

    [updateAlarmThunk.fulfilled]: state => {
      state.alarm.resourceStatus = RESOURCE_STATUS.READY;
      state.alarm.error = null;
    },
    [updateAlarmThunk.pending]: state => {
      state.alarm.resourceStatus = RESOURCE_STATUS.PENDING;
    },
    [updateAlarmThunk.rejected]: (state, action) => {
      state.alarm.resourceStatus = RESOURCE_STATUS.REJECTED;
      state.alarm.error = action.error;
    },
    [deleteAlarmThunk.fulfilled]: state => {
      state.alarm.resourceStatus = RESOURCE_STATUS.READY;
      state.alarm.error = null;
    },
    [deleteAlarmThunk.pending]: state => {
      state.alarm.resourceStatus = RESOURCE_STATUS.PENDING;
    },
    [deleteAlarmThunk.rejected]: (state, action) => {
      state.alarm.resourceStatus = RESOURCE_STATUS.REJECTED;
      state.alarm.error = action.error;
    },
    [updateAlarmsThunk.fulfilled]: (state, action) => {
      const { alarms } = action.payload;

      state.alarms = {
        items: alarms,
        resourceStatus: RESOURCE_STATUS.READY,
      };
    },
    [updateAlarmsThunk.pending]: state => {
      state.alarms.resourceStatus = RESOURCE_STATUS.PENDING;
    },
    [updateAlarmsThunk.rejected]: state => {
      state.alarms.resourceStatus = RESOURCE_STATUS.REJECTED;
    },
    [updateScheduleThunk.fulfilled]: (state, action) => {
      const { schedule } = action.payload;
      state.schedule = {
        ...schedule,
      };
    },
    [updateScheduleThunk.pending]: state => {
      state.schedule.resourceStatus = RESOURCE_STATUS.PENDING;
    },
    [updateScheduleThunk.rejected]: state => {
      state.schedule.resourceStatus = RESOURCE_STATUS.REJECTED;
    },
    [fetchLastReadingThunk.fulfilled]: (state, action) => {
      state.lastReading.value = action.payload;
      state.lastReading.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchLastReadingThunk.pending]: state => {
      state.lastReading.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchLastReadingThunk.rejected]: state => {
      state.lastReading.resourceStatus = RESOURCE_STATUS.REJECTED;
    },
    [calibrateTankMonitorThunk.fulfilled]: state => {
      state.calibration.resourceStatus = RESOURCE_STATUS.READY;
    },
    [calibrateTankMonitorThunk.pending]: state => {
      state.calibration.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [calibrateTankMonitorThunk.rejected]: state => {
      state.calibration.resourceStatus = RESOURCE_STATUS.REJECTED;
    },
    [setupGaugeReaderThunk.fulfilled]: state => {
      state.gaugeReader.resourceStatus = RESOURCE_STATUS.READY;
    },
    [setupGaugeReaderThunk.pending]: state => {
      state.gaugeReader.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [setupGaugeReaderThunk.rejected]: state => {
      state.gaugeReader.resourceStatus = RESOURCE_STATUS.REJECTED;
    },
    [deleteLevelThunk.fulfilled]: state => {
      state.deleteLevel.error = null;
      state.deleteLevel.resourceStatus = RESOURCE_STATUS.READY;
    },
    [deleteLevelThunk.pending]: state => {
      state.deleteLevel.error = null;
      state.deleteLevel.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [deleteLevelThunk.rejected]: (state, action) => {
      state.deleteLevel.error = action.error;
      state.deleteLevel.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [subscribeToMonitorThunk.fulfilled]: state => {
      state.subscribeToMonitor.error = null;
      state.subscribeToMonitor.resourceStatus = RESOURCE_STATUS.READY;
    },
    [subscribeToMonitorThunk.pending]: state => {
      state.subscribeToMonitor.error = null;
      state.subscribeToMonitor.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [subscribeToMonitorThunk.rejected]: (state, action) => {
      state.subscribeToMonitor.error = action.error;
      state.subscribeToMonitor.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [unsubscribeToMonitorThunk.fulfilled]: state => {
      state.unsubscribeToMonitor.error = null;
      state.unsubscribeToMonitor.resourceStatus = RESOURCE_STATUS.READY;
    },
    [unsubscribeToMonitorThunk.pending]: state => {
      state.unsubscribeToMonitor.error = null;
      state.unsubscribeToMonitor.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [unsubscribeToMonitorThunk.rejected]: (state, action) => {
      state.unsubscribeToMonitor.error = action.error;
      state.unsubscribeToMonitor.resourceStatus = RESOURCE_STATUS.ERROR;
    },
  },
});

export const scheduleSelector = state => state.schedule;
export const monitorSelector = state => state.monitor;
export const alarmsSelector = state => state.monitor.alarms;
export const levelsSelector = state => state.monitor.levels;
export const allTankLevelsSelector = state => state.monitor.allTankLevels;
export const fillLevelsSelector = state => state.monitor.fillLevels;
export const readingsSelector = state => state.monitor.readings;
export const selectLastReading = state => monitorSelector(state).lastReading;
export const selectCalibrationStatus = state =>
  monitorSelector(state).calibration.resourceStatus;
export const getFillLevelsCsv = state => state.monitor.fillLevelsExport;
export const getLevelHistoryCsv = state => state.monitor.levelHistoryExport;

export const {
  setDownloadFillLevelsStatusIdle,
  setDownloadLevelHistoryStatusIdle,
} = monitorSlice.actions;

export default monitorSlice.reducer;
