/* eslint-disable no-param-reassign */
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { RESOURCE_STATUS } from 'utils/constants';
import {
  createUser,
  deleteUser,
  fetchUsers,
  fetchUser,
  updateUser,
} from 'api/users';

export const createUserThunk = createAsyncThunk(
  'users/createUser',
  async ({ user }) => {
    try {
      const { data } = await createUser({ user });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const fetchUsersThunk = createAsyncThunk(
  'users/fetchUsers',
  async ({
    page,
    perPage,
    search,
    orderBy,
    orderDirection,
    companyId,
    tankId,
  }) => {
    const { data, headers } = await fetchUsers({
      page,
      perPage,
      search,
      orderBy,
      orderDirection,
      companyId,
      tankId,
    });

    return {
      users: data,
      totalPages: headers['total-pages'],
      currentPage: headers['current-page'],
      totalCount: headers['total-count'],
    };
  },
);

export const fetchUsersSubscriptionsThunk = createAsyncThunk(
  'users/fetchUsersSubscriptions',
  async ({
    page,
    perPage,
    search,
    orderBy,
    orderDirection,
    companyId,
    tankId,
  }) => {
    const { data, headers } = await fetchUsers({
      page,
      perPage,
      search,
      orderBy,
      orderDirection,
      companyId,
      tankId,
    });

    return {
      users: data,
      totalPages: headers['total-pages'],
      currentPage: headers['current-page'],
      totalCount: headers['total-count'],
    };
  },
);

export const fetchUsersAlarmsThunk = createAsyncThunk(
  'users/fetchUsersAlarms',
  async ({
    page,
    perPage,
    search,
    orderBy,
    orderDirection,
    companyId,
    tankId,
  }) => {
    const { data, headers } = await fetchUsers({
      page,
      perPage,
      search,
      orderBy,
      orderDirection,
      companyId,
      tankId,
    });

    return {
      users: data,
      totalPages: headers['total-pages'],
      currentPage: headers['current-page'],
      totalCount: headers['total-count'],
    };
  },
);

export const fetchUserThunk = createAsyncThunk(
  'users/fetchUser',
  async ({ id }) => {
    const { data } = await fetchUser(id);
    return {
      user: data,
    };
  },
);

export const updateUserThunk = createAsyncThunk(
  'users/updateUser',
  async ({ user }) => {
    try {
      const { data } = await updateUser({ user });
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

export const deleteUserThunk = createAsyncThunk(
  'users/deleteUser',
  async ({ userId }) => {
    try {
      const { data } = await deleteUser(userId);
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data?.message) {
        throw new Error(response.data.message);
      }
      throw error;
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    list: {
      items: [],
      totalPages: 1,
      currentPage: 1,
      totalCount: 0,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    userSubscriptions: {
      items: [],
      totalPages: 1,
      currentPage: 1,
      totalCount: 0,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    userAlarms: {
      items: [],
      totalPages: 1,
      currentPage: 1,
      totalCount: 0,
      resourceStatus: RESOURCE_STATUS.IDLE,
    },
    creation: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    delete: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
    selectedUser: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
      value: null,
    },
    update: {
      resourceStatus: RESOURCE_STATUS.IDLE,
      error: null,
    },
  },
  extraReducers: {
    [fetchUsersThunk.fulfilled]: (state, action) => {
      state.list.items = action.payload.users;
      state.list.totalPages = Number(action.payload.totalPages);
      state.list.currentPage = Number(action.payload.currentPage);
      state.list.totalCount = Number(action.payload.totalCount);
      state.list.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchUsersThunk.pending]: state => {
      state.list.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchUsersThunk.rejected]: state => {
      state.list.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchUsersSubscriptionsThunk.fulfilled]: (state, action) => {
      state.userSubscriptions.items = action.payload.users;
      state.userSubscriptions.totalPages = Number(action.payload.totalPages);
      state.userSubscriptions.currentPage = Number(action.payload.currentPage);
      state.userSubscriptions.totalCount = Number(action.payload.totalCount);
      state.userSubscriptions.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchUsersSubscriptionsThunk.pending]: state => {
      state.userSubscriptions.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchUsersSubscriptionsThunk.rejected]: state => {
      state.userSubscriptions.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchUsersAlarmsThunk.fulfilled]: (state, action) => {
      state.userAlarms.items = action.payload.users;
      state.userAlarms.totalPages = Number(action.payload.totalPages);
      state.userAlarms.currentPage = Number(action.payload.currentPage);
      state.userAlarms.totalCount = Number(action.payload.totalCount);
      state.userAlarms.resourceStatus = RESOURCE_STATUS.READY;
    },
    [fetchUsersAlarmsThunk.pending]: state => {
      state.userAlarms.resourceStatus = RESOURCE_STATUS.LOADING;
    },
    [fetchUsersAlarmsThunk.rejected]: state => {
      state.userAlarms.resourceStatus = RESOURCE_STATUS.ERROR;
    },
    [fetchUserThunk.fulfilled]: (state, action) => {
      state.selectedUser.resourceStatus = RESOURCE_STATUS.READY;
      state.selectedUser.value = action.payload.user;
      state.selectedUser.error = null;
    },
    [fetchUserThunk.pending]: state => {
      state.selectedUser.resourceStatus = RESOURCE_STATUS.LOADING;
      state.selectedUser.error = null;
    },
    [fetchUserThunk.rejected]: (state, action) => {
      state.selectedUser.resourceStatus = RESOURCE_STATUS.ERROR;
      state.selectedUser.error = action.error;
    },
    [createUserThunk.fulfilled]: state => {
      state.creation.resourceStatus = RESOURCE_STATUS.READY;
      state.creation.error = null;
    },
    [createUserThunk.pending]: state => {
      state.creation.resourceStatus = RESOURCE_STATUS.LOADING;
      state.creation.error = null;
    },
    [createUserThunk.rejected]: (state, action) => {
      state.creation.resourceStatus = RESOURCE_STATUS.ERROR;
      state.creation.error = action.error;
    },
    [updateUserThunk.fulfilled]: state => {
      state.update.resourceStatus = RESOURCE_STATUS.READY;
      state.update.error = null;
    },
    [updateUserThunk.pending]: state => {
      state.update.resourceStatus = RESOURCE_STATUS.LOADING;
      state.update.error = null;
    },
    [updateUserThunk.rejected]: (state, action) => {
      state.update.resourceStatus = RESOURCE_STATUS.ERROR;
      state.update.error = action.error;
    },
    [deleteUserThunk.fulfilled]: state => {
      state.delete.resourceStatus = RESOURCE_STATUS.READY;
      state.delete.error = null;
    },
    [deleteUserThunk.pending]: state => {
      state.delete.resourceStatus = RESOURCE_STATUS.LOADING;
      state.delete.error = null;
    },
    [deleteUserThunk.rejected]: (state, action) => {
      state.delete.resourceStatus = RESOURCE_STATUS.ERROR;
      state.delete.error = action.error;
    },
  },
});

const getUsersSlice = state => state.users;

export const getUserCreationStatus = createSelector(
  getUsersSlice,
  state => state.creation.resourceStatus,
);

export const getUserCreationError = createSelector(
  getUsersSlice,
  state => state.creation.error,
);

export const getUsers = createSelector(
  getUsersSlice,
  state => state.list,
);

export const getUsersSubscriptions = createSelector(
  getUsersSlice,
  state => state.userSubscriptions,
);

export const getUsersAlarms = createSelector(
  getUsersSlice,
  state => state.userAlarms,
);

export const getSelectedUser = createSelector(
  getUsersSlice,
  state => state.selectedUser,
);

export const getUpdateData = createSelector(
  getUsersSlice,
  state => state.update,
);

export default usersSlice.reducer;
