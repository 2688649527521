import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/titillium-web/700.css';
import '@fontsource/titillium-web/400.css';
import '@fontsource/raleway/400.css';
import '@fontsource/montserrat/400.css';
import { getCurrentUser } from 'stores/currentUser';
import { useSelector } from 'react-redux';
import { THEME, KBC_URL } from 'utils/constants';
import themes from './theme';

const ThemeProvider = ({ children }) => {
  const currentUser = useSelector(getCurrentUser);
  const [loginUrl, setLoginUrl] = useState('');
  const style = loginUrl === KBC_URL ? THEME.KBC : THEME.XACT;
  const handleColorTheme = () => {
    if (currentUser?.companyName === 'KBC' || style === 'KBC') {
      return 'kbcTheme';
    }
    return 'default';
  };
  const colorTheme = handleColorTheme();
  const [theme, setTheme] = useState(themes[colorTheme]);

  useEffect(() => {
    setLoginUrl(window.location.origin);
    setTheme(themes[colorTheme]);
  }, [colorTheme]);

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
