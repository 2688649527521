export const NumberInput = {
  baseStyle: {
    field: {
      background: 'transparent',
      border: 'unset',
      padding: 2,
      borderRadius: 5,
      fontWeight: '400',
      lineHeight: 4,
      color: 'gray.700',
      _placeholder: {
        color: 'gray.400',
      },
    },
  },
  sizes: {
    base: {
      field: {
        fontSize: 'sm',
        height: '40px',
        _placeholder: {
          fontSize: 'md',
        },
      },
    },
    medium: {
      field: {
        fontSize: 'md',
        height: '40px',
        _placeholder: {
          fontSize: 'md',
        },
      },
    },
    '3xl': {
      field: {
        fontSize: '3xl',
        height: '40px',
        _placeholder: {
          fontSize: '3xl',
        },
      },
    },
    '2xl': {
      field: {
        fontSize: '2xl',
        height: '40px',
        _placeholder: {
          fontSize: '2xl',
        },
      },
    },
  },
  variants: {
    base: {
      field: {
        border: '1px solid',
        borderColor: 'gray.500',
        _focus: {
          borderColor: 'alpha.500',
        },
      },
    },
    headingNoBorders: {
      field: {
        border: 'transparent',
        fontWeight: '700',
        background: 'transparent',
        _focus: {
          border: '1px solid',
          borderColor: 'gray.500',
        },
      },
    },
  },
  defaultProps: {
    size: 'base',
    variant: 'base',
  },
};
