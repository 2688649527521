import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import getConfig from 'next/config';
import { authReducer } from './authReducer';
import currentUserReducer from './currentUser';
import monitorReducer from './monitor';
import monitorsReducer from './monitors';
import companiesReducer from './companies';
import companyReducer from './company';
import usersReducer from './users';
import zonesReducer from './zones';
import pricePlansReducer from './pricePlans';
import billingReducer from './billing';
import invoicingReducer from './invoicing';
import productsReducer from './products';
import companyDiscountsReducer from './companyDiscounts';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const {
  publicRuntimeConfig: { SENTRY_DSN, SENTRY_ENVIRONMENT },
} = getConfig();

const reducers = combineReducers({
  auth: authReducer,
  currentUser: currentUserReducer,
  monitor: monitorReducer,
  monitors: monitorsReducer,
  companies: companiesReducer,
  company: companyReducer,
  users: usersReducer,
  zones: zonesReducer,
  pricePlans: pricePlansReducer,
  billing: billingReducer,
  invoicing: invoicingReducer,
  products: productsReducer,
  companyDiscounts: companyDiscountsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
});

const sentryEnhancer = Sentry.createReduxEnhancer({});

export function initializeStore(preloadedState) {
  return configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    preloadedState,
    enhancers: [sentryEnhancer],
  });
}
