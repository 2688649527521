import axios from 'lib/axios';

export const fetchZonesByCompany = ({ companyId, search, alarm }) =>
  axios.get('/zones', {
    params: {
      current_company_id: companyId,
      name: search,
      alarm,
      with_alarms_count: true,
    },
  });

export const createZone = ({ companyId, name, parentId, billable }) =>
  axios.post('/zones', {
    company_id: companyId,
    parent_id: parentId,
    billable,
    name,
  });

export const updateZone = ({ id, companyId, name, parentId, billable }) =>
  axios.put(`/zones/${id}`, {
    company_id: companyId,
    parent_id: parentId,
    billable,
    name,
  });

export const deleteZone = ({ id }) => axios.delete(`/zones/${id}`);
